import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import strings from "./global/constants/StringConstants";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: "AIzaSyAFKFDvqgJ-ye8vUP6H0S6DrVMrfi2lkLc",
  authDomain: "astrology-7779c.firebaseapp.com",
  projectId: "astrology-7779c",
  storageBucket: "astrology-7779c.appspot.com",
  messagingSenderId: "716191937417",
  appId: "1:716191937417:web:18b030ce06d54bd5dfa758",
  measurementId: "G-0EFCRMJX2H",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    if (
      typeof window.Notification !== "undefined" &&
      "Notification" in window
    ) {
      const permission = await window.Notification.requestPermission();
      if (permission === "granted") {
        const currentToken = await getToken(messaging, {
          vapidKey: strings.VAPID_KEY,
        });
        if (currentToken) {
          return currentToken;
        } else {
          throw { errorMessage: "No registration token available." };
        }
      } else {
        throw { errorMessage: "Permission denied for notifications." };
      }
    } else {
      console.log("Error from browser");
    }
  } catch (error: any) {
    throw error;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
