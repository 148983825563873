import { mediumFont, theme } from "../../utils/styles";

const professionAnalysisStyle = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 195px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    margin: "25px 0px",
    maxWidth: "1750px",
    width: "100%",
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 235px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: "50px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  darkTextField: {
    borderRadius: "0px",
    border: "none",
    background: "transparent",
    boxShadow: "none",

    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "0px 0px",
      color: "#ffffff",
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 0px",
      },

      "&::placeholder": {
        ...mediumFont,
        color: "#ffffffA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#ffffff",
      top: "calc(50% - 0.6em)",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
} as const;

export default professionAnalysisStyle;
