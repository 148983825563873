import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, CustomInput } from "../../global/components";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import strings from "../../global/constants/StringConstants";
import { handleErrorMessage, isTruthy } from "../../helpers/methods";
import { formValidation } from "../Analysis/Components/Football/FootballValidation";
import {
  days,
  formData,
  hours,
  minutesSeconds,
  years,
} from "../Calculator/CalculatorData";
import { professionPrediction } from "./ProfessionAnalysisService";
import ProfessionGraph from "./ProfessionGraph";

declare const google: any;

interface CustomProps {
  setIsLoading: Function;
}

const Profession = (props: CustomProps) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<any>({
    latitude: "",
    longitude: "",
    location: "",
  });
  const [formFields, setFormFields] = useState(formData);
  const [graphData, setGraphData] = useState<any>();
  const [profession, setProfession] = useState<string>("");
  // const emptyGraphData: any = {
  //   Actress: "0.32",
  //   Murderer: "0.14",
  //   President: "0.10",
  //   Scientist: "0.26",
  //   Sport: "0.18",
  // };

  const yearMenu = [
    { value: 0, content: `${t("year")}`, disabled: true },
    ...years,
  ];

  const monthMenu = [
    { value: 0, content: `${t("month")}`, disabled: true },
    { content: `${t("January")}`, value: 1 },
    { content: `${t("February")}`, value: 2 },
    { content: `${t("March")}`, value: 3 },
    { content: `${t("April")}`, value: 4 },
    { content: `${t("May")}`, value: 5 },
    { content: `${t("June")}`, value: 6 },
    { content: `${t("July")}`, value: 7 },
    { content: `${t("August")}`, value: 8 },
    { content: `${t("September")}`, value: 9 },
    { content: `${t("October")}`, value: 10 },
    { content: `${t("November")}`, value: 11 },
    { content: `${t("December")}`, value: 12 },
  ];

  const dateMenu = [
    { value: 0, content: `${t("date")}`, disabled: true },
    ...days,
  ];

  const hoursMenu = [
    { value: -1, content: `${t("hour")}`, disabled: true },
    ...hours,
  ];

  const minutesMenu = [
    { value: -1, content: `${t("minute")}`, disabled: true },
    ...minutesSeconds,
  ];

  const secondsMenu = [
    { value: -1, content: `${t("second")}`, disabled: true },
    ...minutesSeconds,
  ];

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    address.location &&
      setFormFields({
        ...formFields,
        birthPlace: {
          ...formFields.birthPlace,
          value: address.location,
        },
      });
    !formFields.birthPlace.value &&
      setAddress({
        ...address,
        location: "",
      });
  }, [address.location]);

  const handlePlaceChanged = async (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const { geometry } = place;
    if (geometry && geometry.location) {
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      setAddress({
        ...address,
        latitude: latitude,
        longitude: longitude,
        location: place.formatted_address,
      });
    }
  };

  const initialize = () => {
    const input: any = document.getElementById("birthPlace");
    const autocomplete: any = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  };

  const handleValidation = () => {
    const { isValid, errors } = formValidation(formFields, address);
    setFormFields({ ...errors });
    return isValid;
  };

  const handlePredict = async () => {
    const birth_date = `${formFields.year.value}-${moment(
      formFields.month.value,
      "M"
    ).format("MM")}-${moment(formFields.date.value, "D").format("DD")} ${moment(
      formFields.hours.value,
      "H"
    ).format("HH")}:${moment(formFields.minutes.value, "m").format(
      "mm"
    )}:${moment(formFields.seconds.value, "s").format("ss")}`;

    try {
      if (handleValidation()) {
        const profileData = {
          name: formFields.firstName.value.trim(),
          birth_date: birth_date,
          longitude: `${address.longitude}`,
          latitude: `${address.latitude}`,
          altitude: "0",
          blood_group: formFields.bloodGroup.value,
          timezone: null,
          isVedic: "1",
        };
        props.setIsLoading(true);
        const response: Record<string, string> = await professionPrediction(
          profileData
        );
        setGraphData(response);
        const keyOfMaxValue = Object.entries(response).reduce(
          (maxKey, [key, value]) =>
            parseFloat(value) > parseFloat(response[maxKey]) ? key : maxKey,
          Object.keys(response)[0]
        );
        setProfession(keyOfMaxValue);
        props.setIsLoading(false);
      }
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleFieldChange = (event: any) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
    if (event.target.name === "birthPlace") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const getProfessionFormComponent = () => {
    return (
      <Grid container spacing={1} justifyContent={"center"} rowGap={"10px"}>
        <Grid item xl={4.8} lg={4.8} md={4.8} sm={12} xs={12}>
          <CustomInput
            required
            label={t("firstName")}
            name="firstName"
            value={formFields.firstName.value}
            onChange={handleFieldChange}
            placeHolder={t("firstNamePlaceholder")}
            error={
              !isTruthy(formFields.firstName.value) &&
              formFields.firstName.error
            }
          />
        </Grid>
        <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("birthDate")}
            menuItems={yearMenu}
            onChange={handleFieldChange}
            id={"year"}
            name={"year"}
            value={formFields.year.value}
            error={formFields.year.error}
          />
        </Grid>
        <Grid
          item
          xl={2.4}
          lg={2.4}
          md={2.4}
          sm={12}
          xs={12}
          mt={{ md: 4, xs: 0 }}
        >
          <CustomSelect
            label=""
            menuItems={monthMenu}
            onChange={handleFieldChange}
            id={"month"}
            name={"month"}
            value={formFields.month.value}
            error={formFields.month.error}
          />
        </Grid>
        <Grid
          item
          xl={2.4}
          lg={2.4}
          md={2.4}
          sm={12}
          xs={12}
          mt={{ md: 4, xs: 0 }}
        >
          <CustomSelect
            menuItems={dateMenu}
            onChange={handleFieldChange}
            id={"date"}
            name={"date"}
            value={formFields.date.value}
            error={formFields.date.error}
          />
        </Grid>
        <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("birthTime")}
            menuItems={hoursMenu}
            onChange={handleFieldChange}
            id={"hours"}
            name={"hours"}
            value={formFields.hours.value}
            error={formFields.hours.error}
          />
        </Grid>
        <Grid
          item
          xl={2.4}
          lg={2.4}
          md={2.4}
          sm={12}
          xs={12}
          mt={{ md: 4, xs: 0 }}
        >
          <CustomSelect
            menuItems={minutesMenu}
            onChange={handleFieldChange}
            id={"minutes"}
            name={"minutes"}
            value={formFields.minutes.value}
            error={formFields.minutes.error}
          />
        </Grid>
        <Grid
          item
          xl={2.4}
          lg={2.4}
          md={2.4}
          sm={12}
          xs={12}
          mt={{ md: 4, xs: 0 }}
        >
          <CustomSelect
            menuItems={secondsMenu}
            onChange={handleFieldChange}
            id={"seconds"}
            name={"seconds"}
            value={formFields.seconds.value}
            error={formFields.seconds.error}
          />
        </Grid>
        <Grid item xl={4.8} lg={4.8} md={4.8} sm={12} xs={12}>
          <CustomInput
            required
            label={t("birthPlace")}
            placeHolder={t("place")}
            id="birthPlace"
            type="birthPlace"
            name="birthPlace"
            value={formFields.birthPlace.value}
            onChange={handleFieldChange}
            error={
              !isTruthy(
                formFields.birthPlace.value &&
                  address.latitude &&
                  address.longitude
              ) && formFields.birthPlace.error
            }
          />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} mt={3}>
          <CustomButton
            label={t("predict")}
            type={strings.PRIMARY}
            onClick={handlePredict}
          />
        </Grid>
      </Grid>
    );
  };

  const getProfessionGraphComponent = () => {
    return (
      <Box>
        {graphData && (
          <ProfessionGraph percentages={graphData} needColor={true} />
          // ) : (
          //   <ProfessionGraph percentages={emptyGraphData} needColor={false} />
        )}
      </Box>
    );
  };

  const getProfession = () => {
    return (
      <Box>
        {getProfessionFormComponent()}
        {getProfessionGraphComponent()}
      </Box>
    );
  };

  return getProfession();
};

export default Profession;
