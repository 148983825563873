import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import astrologyLogo from "../../../assets/icons/astrologyLogo.svg";
import englishIcon from "../../../assets/icons/englishIcon.svg";
import AstrologyLogo from "../../../assets/icons/logo.svg";
import logoutIcon from "../../../assets/icons/logoutIcon.svg";
import notificationDarkIcon from "../../../assets/icons/notificationDarkIcon.svg";
import notificationIcon from "../../../assets/icons/notificationIcon.svg";
import polskiIcon from "../../../assets/icons/polskiIcon.svg";
import professionIcon from "../../../assets/icons/professionIcon.svg";
import profileIcon from "../../../assets/icons/profileIcon.svg";
import profilesIcon from "../../../assets/icons/profilesIcon.svg";
import resetPasswordIcon from "../../../assets/icons/resetPasswordIcon.svg";
import subscriptionIcon from "../../../assets/icons/subscriptionIcon.svg";
import userProfileIcon from "../../../assets/icons/userProfileIcon.svg";
import { onMessageListener, requestForToken } from "../../../firebase";
import { CustomDialog } from "../../../global/components";
import CustomSelect from "../../../global/components/CustomSelect/CustomSelect";
import notifiers from "../../../global/constants/NotificationConstants";
import strings from "../../../global/constants/StringConstants";
import urls from "../../../global/constants/UrlConstants";
import {
  getMoonPhasesSymbols,
  isTruthy,
  openSuccessNotification,
} from "../../../helpers/methods";
import {
  firebaseTokenAction,
  logOutAction,
  selectAuthenticated,
  selectFirebaseToken,
  selectFirstName,
  selectRole,
  setLanguageAction,
} from "../../../redux/authSlice";
import { doesPageHasComponent } from "../../../utils/AuthorizationManager";
import history from "../../../utils/history";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { store } from "../../../utils/store";
import { theme } from "../../../utils/styles";
import { setLanguagePreference } from "../../Auth/AuthService";
import { setNotificationsToken } from "../../Dashboard/DashboardService";
import appHeaderStyles from "./AppHeader.styles";
import {
  getChoghadiya,
  getFullChoghadiya,
  getFullHora,
  getHora,
  getMoonPhases,
  getNotificationCount,
  logout,
} from "./AppHeaderServices";
import ChoghadiyaDrawer from "./ChoghadiyaDrawer";
import HoraDrawer from "./HoraDrawer";
import NotificationDrawer from "./NotificationDrawer";

interface CustomProps {
  toggleDrawer: Function;
  location?: any;
}

const AppHeader = (props: CustomProps) => {
  const classes = appHeaderStyles;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const firebaseToken = useAppSelector(selectFirebaseToken);
  const role = useAppSelector(selectRole);
  const user = useAppSelector(selectFirstName);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language.toLowerCase() === "pl" ? i18n.language.toLowerCase() : "en"
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [openHora, setOpenHora] = useState<boolean>(false);
  const [openChoghadiya, setOpenChoghadiya] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>(dayjs().format("DD-MM-YYYY"));
  const [notificationCount, setNotificationCount] = useState(0);
  const [moonDetails, setMoonDetails] = useState({
    moonPhase: 0,
    nakshatra: "",
  });
  const [choghadiyaDetails, setChoghadiyaDetails] = useState({
    end_time: "",
    nakshatra: "",
    start_time: "",
  });
  const [horaDetails, setHoraDetails] = useState({
    end_time: "",
    nakshatra: "",
    start_time: "",
  });
  const [fullHoraDetails, setFullHoraDetails] = useState({
    dayHora: [],
    nightHora: [],
    todaySunrise: "",
    todaySunset: "",
  });
  const [fullChoghadiyaDetails, setFullChoghadiyaDetails] = useState({
    dayHora: [],
    nightHora: [],
    todaySunrise: "",
    todaySunset: "",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const displayedNotifications: Set<string> = new Set();

  useEffect(() => {
    if (isAuthenticated) {
      getHeaderData();
      const intervalId = setInterval(() => {
        getHeaderData();
      }, 3600000);
      return () => clearInterval(intervalId);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (isAuthenticated) {
      getHoraData();
    }
  }, [i18n.language, date]);

  useEffect(() => {
    if (isAuthenticated) {
      getNotificationCounts();
      const intervalId = setInterval(() => {
        getNotificationCounts();
      }, 600000);
      return () => clearInterval(intervalId);
    }
  }, [openNotification]);

  useEffect(() => {
    const language = localStorage.getItem("userLanguage")
      ? localStorage.getItem("userLanguage")
      : "pl";
    dispatch(
      setLanguageAction({
        language: language!,
      })
    );
    setSelectedLanguage(language!);
    i18n.changeLanguage(language!);
    localStorage.setItem("userLanguage", language!);
  }, []);

  useEffect(() => {
    if (
      typeof window.Notification !== "undefined" &&
      "Notification" in window
    ) {
      if (window.Notification.permission === "granted") {
        !firebaseToken && setClientToken();
      }
    }
  }, [
    typeof window.Notification !== "undefined" &&
      "Notification" in window &&
      window.Notification.permission,
  ]);

  const setClientToken = async () => {
    try {
      const token: any = await requestForToken();
      dispatch(
        firebaseTokenAction({
          firebaseToken: token,
        })
      );
      await setNotificationsToken(token);
    } catch (error: any) {
      console.log(error.errorMessage);
    }
  };

  if (typeof window.Notification !== "undefined" && "Notification" in window) {
    onMessageListener()
      .then((payload: any) => {
        const title = payload?.notification?.title;
        const body = payload?.notification?.body;
        const messageId = payload?.data?.messageId;
        const options = {
          body: body,
          icon: astrologyLogo,
          tag: messageId,
        };

        if (window.Notification.permission === "granted" && title && body) {
          if (!displayedNotifications.has(messageId)) {
            getNotificationCounts();
            openSuccessNotification(body, title);
            new Notification(title, options);
            displayedNotifications.add(messageId);
          }
        }
      })
      .catch((err) => console.log("failed: ", err));
  }

  const adminMenuItem = [
    {
      title: `${t("profiles")}`,
      onclick: () => history.push(urls.dashboardViewPath),
      icon: profilesIcon,
    },
    {
      title: `${t("profession")}`,
      onclick: () => history.push(urls.professionAnalysisViewPath),
      icon: professionIcon,
    },
    {
      title: `${t("profile")}`,
      onclick: () => history.push(urls.userProfileViewPath),
      icon: userProfileIcon,
    },
    {
      title: `${t("subscription")}`,
      onclick: () => history.push(urls.subscriptionViewPath),
      icon: subscriptionIcon,
    },
    {
      title: `${t("updatePassword")}`,
      onclick: () => history.push(urls.updatePasswordViewPath),
      icon: resetPasswordIcon,
    },
    {
      title: `${t("logout")}`,
      onclick: () => setOpenDialog(true),
      icon: logoutIcon,
    },
  ];

  const menuItems = [
    {
      title: `${t("profiles")}`,
      onclick: () => history.push(urls.dashboardViewPath),
      icon: profilesIcon,
    },
    {
      title: `${t("profile")}`,
      onclick: () => history.push(urls.userProfileViewPath),
      icon: userProfileIcon,
    },
    {
      title: `${t("subscription")}`,
      onclick: () => history.push(urls.subscriptionViewPath),
      icon: subscriptionIcon,
    },
    {
      title: `${t("updatePassword")}`,
      onclick: () => history.push(urls.updatePasswordViewPath),
      icon: resetPasswordIcon,
    },
    {
      title: `${t("logout")}`,
      onclick: () => setOpenDialog(true),
      icon: logoutIcon,
    },
  ];

  const menuList: any = [
    {
      header: [
        {
          value: "pl",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              <img src={polskiIcon} height={"35px"} alt="Module Not Found..." />
            </Stack>
          ),
          selected: true,
        },
        {
          value: "en",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              <img
                src={englishIcon}
                height={"35px"}
                alt="Module Not Found..."
              />
            </Stack>
          ),
          selected: false,
        },
      ],
    },
    {
      menu: [
        {
          value: "pl",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              <img src={polskiIcon} height={"35px"} alt="Module Not Found..." />
              <Typography sx={classes.languageTypo}>Polski</Typography>
            </Stack>
          ),
          selected: true,
        },
        {
          value: "en",
          content: (
            <Stack direction="row" alignItems={"center"} spacing={1.5}>
              {isMobile ? (
                <img
                  src={englishIcon}
                  height={"25px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={englishIcon}
                  height={"30px"}
                  alt="Module Not Found..."
                />
              )}
              <Typography sx={classes.languageTypo}>English</Typography>
            </Stack>
          ),
          selected: false,
        },
      ],
    },
  ];

  const getNotificationCounts = async () => {
    try {
      const response = await getNotificationCount();
      setNotificationCount(response.count);
    } catch (error: any) {
      console.log(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const getHeaderData = async () => {
    try {
      setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const [moon, choghadiya, hora] = await Promise.all([
        getMoonPhases(language),
        getChoghadiya(language),
        getHora(language),
      ]);
      setMoonDetails(moon);
      setChoghadiyaDetails(choghadiya);
      setHoraDetails(hora);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const getHoraData = async () => {
    try {
      setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const [fullHora, fullChoghadiya] = await Promise.all([
        getFullHora(date, language),
        getFullChoghadiya(date, language),
      ]);
      setFullHoraDetails(fullHora);
      setFullChoghadiyaDetails(fullChoghadiya);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    dispatch(
      setLanguageAction({
        language: event.target.value,
      })
    );
    localStorage.setItem("userLanguage", event.target.value);
    isAuthenticated && setLanguagePreference(event.target.value);
  };

  const handleLogout = async () => {
    try {
      await logout(firebaseToken);
      setTimeout(() => {
        history.push(urls.homeViewPath);
        store.dispatch(logOutAction());
      }, 1000);
    } catch (error: any) {
      console.log(error.errorMessage);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getAstrologyLogo = () => {
    return (
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() =>
          history.push(
            isAuthenticated ? urls.dashboardViewPath : urls.homeViewPath
          )
        }
      >
        {isMobile ? (
          <img src={AstrologyLogo} alt="Module Not Found..." width={"130px"} />
        ) : (
          <img src={AstrologyLogo} alt="Module Not Found..." height={"80px"} />
        )}
      </Box>
    );
  };

  const getMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: classes.menuWrapper,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isAuthenticated && (
          <>
            {(() => {
              const selectedMenu =
                role === strings.ADMIN ? adminMenuItem : menuItems;
              return selectedMenu.map((menu: any, index: number) => (
                <React.Fragment key={index}>
                  <MenuItem onClick={menu.onclick}>
                    <ListItemIcon>
                      <img
                        src={menu.icon}
                        height="20px"
                        alt="Module Not Found..."
                      />
                    </ListItemIcon>
                    <Typography sx={classes.menuItemText}>
                      {menu.title}
                    </Typography>
                  </MenuItem>
                  {index + 1 !== selectedMenu.length && <Divider />}
                </React.Fragment>
              ));
            })()}
          </>
        )}

        {isTablet && (
          <>
            {isAuthenticated && (
              <>
                <Divider />
                <MenuItem onClick={() => setOpenNotification(true)}>
                  <ListItemIcon>
                    <Badge
                      badgeContent={notificationCount}
                      sx={classes.notificationBadge}
                    >
                      <img
                        src={notificationDarkIcon}
                        height={"20px"}
                        alt="Module Not Found..."
                      />
                    </Badge>
                  </ListItemIcon>
                  <Typography sx={classes.menuItemText}>
                    Notifications
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  sx={{ pt: 1 }}
                  onClick={() => setOpenChoghadiya(true)}
                >
                  <Box sx={{ ...classes.moonDarkBox, py: 1, width: "100%" }}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      <img
                        src={lampIcon}
                        height={"40px"}
                        alt="Module Not Found..."
                      />
                      <Box>
                        <Typography
                          sx={{
                            ...classes.moonHeader,
                            whiteSpace: "nowrap",
                            color: "#000000",
                          }}
                          pb={0.5}
                        >
                          {choghadiyaDetails.nakshatra}
                        </Typography>
                        <Typography
                          sx={{
                            ...classes.moonDesc,
                            whiteSpace: "nowrap",
                            color: "#000000",
                          }}
                        >
                          {moment(
                            choghadiyaDetails.start_time,
                            "DD-MM-YYYY HH:mm:ss"
                          ).format("HH:mm")}{" "}
                          to{" "}
                          {moment(
                            choghadiyaDetails.end_time,
                            "DD-MM-YYYY HH:mm:ss"
                          ).format("HH:mm")}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </MenuItem> */}
                <MenuItem sx={{ pt: 1 }} onClick={() => setOpenHora(true)}>
                  <Box
                    sx={{ ...classes.moonDarkBox, px: 2, width: "100%" }}
                    py={0.8}
                  >
                    <Typography
                      sx={{
                        ...classes.moonHeader,
                        whiteSpace: "nowrap",
                        color: "#000000",
                      }}
                      pb={0.5}
                    >
                      {horaDetails.nakshatra}
                    </Typography>
                    <Typography
                      sx={{
                        ...classes.moonDesc,
                        whiteSpace: "nowrap",
                        color: "#000000",
                      }}
                    >
                      {moment(
                        horaDetails.start_time,
                        "DD-MM-YYYY HH:mm:ss"
                      ).format("HH:mm")}{" "}
                      to{" "}
                      {moment(
                        horaDetails.end_time,
                        "DD-MM-YYYY HH:mm:ss"
                      ).format("HH:mm")}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem>
                  <Box sx={{ ...classes.moonDarkBox, py: 1 }}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      <img
                        src={getMoonPhasesSymbols(moonDetails.moonPhase)}
                        height={"40px"}
                        alt="Module Not Found..."
                      />
                      <Box>
                        <Typography
                          sx={{ ...classes.moonHeader, color: "#000000" }}
                        >
                          {t("vedicMoon")}
                        </Typography>
                        <Typography
                          sx={{ ...classes.moonDesc, color: "#000000" }}
                        >
                          {moonDetails.nakshatra}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </MenuItem>
              </>
            )}

            <MenuItem sx={{ pt: 1 }}>
              <CustomSelect
                menuItems={menuList[1].menu}
                onChange={handleLanguageChange}
                value={selectedLanguage}
                name={"language"}
                id={"language"}
                customClasses={classes.darkTextField}
              />
            </MenuItem>
          </>
        )}
      </Menu>
    );
  };

  const getHeaderContent = () => {
    return (
      <Stack direction={"row"} spacing={2}>
        {/* {doesPageHasComponent(strings.MOON) && (
          <Box sx={{ ...classes.vedicMoonBox, cursor: "pointer" }} py={0.8}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              height={"100%"}
            >
              <img src={lampIcon} height={"35px"} alt="Module Not Found..." />
              {isLoading ? (
                <Box>
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "21px",
                      minWidth: "100px",
                      background: "#ffffff70",
                    }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "21px",
                      minWidth: "100px",
                      background: "#ffffff70",
                    }}
                  />
                </Box>
              ) : (
                <Box onClick={() => setOpenChoghadiya(true)}>
                  <Typography
                    sx={{ ...classes.moonHeader, whiteSpace: "nowrap" }}
                    pb={0.5}
                  >
                    {choghadiyaDetails.nakshatra}
                  </Typography>
                  <Typography
                    sx={{ ...classes.moonDesc, whiteSpace: "nowrap" }}
                  >
                    {moment(
                      choghadiyaDetails.start_time,
                      "DD-MM-YYYY HH:mm:ss"
                    ).format("HH:mm")}{" "}
                    to{" "}
                    {moment(
                      choghadiyaDetails.end_time,
                      "DD-MM-YYYY HH:mm:ss"
                    ).format("HH:mm")}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        )} */}
        {doesPageHasComponent(strings.MOON) &&
          (isLoading ? (
            <Box sx={{ ...classes.vedicMoonBox, px: 2 }} py={0.8}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "21px",
                  minWidth: "100px",
                  background: "#ffffff70",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "21px",
                  minWidth: "100px",
                  background: "#ffffff70",
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{ ...classes.vedicMoonBox, px: 2, cursor: "pointer" }}
              py={0.8}
              onClick={() => setOpenHora(true)}
            >
              <Typography
                sx={{ ...classes.moonHeader, whiteSpace: "nowrap" }}
                pb={0.5}
              >
                {horaDetails.nakshatra}
              </Typography>
              <Typography sx={{ ...classes.moonDesc, whiteSpace: "nowrap" }}>
                {moment(horaDetails.start_time, "DD-MM-YYYY HH:mm:ss").format(
                  "HH:mm"
                )}{" "}
                to{" "}
                {moment(horaDetails.end_time, "DD-MM-YYYY HH:mm:ss").format(
                  "HH:mm"
                )}
              </Typography>
            </Box>
          ))}
        {doesPageHasComponent(strings.MOON) && (
          <Box sx={classes.vedicMoonBox} py={0.8}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              height={"100%"}
            >
              {xl ? (
                <img
                  src={getMoonPhasesSymbols(moonDetails.moonPhase)}
                  height={"40px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={getMoonPhasesSymbols(moonDetails.moonPhase)}
                  height={"50px"}
                  alt="Module Not Found..."
                />
              )}
              {isLoading ? (
                <Box>
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "21px",
                      minWidth: "100px",
                      background: "#ffffff70",
                    }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "21px",
                      minWidth: "100px",
                      background: "#ffffff70",
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{ ...classes.moonHeader, whiteSpace: "nowrap" }}
                    pb={0.5}
                  >
                    {t("vedicMoon")}
                  </Typography>
                  <Typography
                    sx={{ ...classes.moonDesc, whiteSpace: "nowrap" }}
                  >
                    {moonDetails.nakshatra}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        )}
        {doesPageHasComponent(strings.NOTIFICATION) && (
          <Box
            sx={classes.notificationBox}
            onClick={() => setOpenNotification(true)}
          >
            <Badge
              badgeContent={notificationCount}
              sx={classes.notificationBadge}
            >
              <img
                src={notificationIcon}
                height={"22px"}
                alt="Module Not Found..."
              />
            </Badge>
          </Box>
        )}
        <CustomSelect
          menuItems={menuList[0].header}
          onChange={handleLanguageChange}
          value={selectedLanguage}
          name={"language"}
          id={"language"}
          customClasses={classes.textField}
        />
        {doesPageHasComponent(strings.PROFILE) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              ...classes.moonBox,
              px: 1,
            }}
          >
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {xl ? (
                <img
                  src={profileIcon}
                  height={"35px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={profileIcon}
                  height={"40px"}
                  alt="Module Not Found..."
                />
              )}
            </IconButton>
          </Box>
        )}
      </Stack>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {user} , {t("logoutHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        pb={5}
        spacing={2}
        sx={classes.dialogContent}
      >
        <Typography sx={classes.lightFont}>{t("logoutContent")}</Typography>
        <Stack direction={{ sm: "row", xs: "column" }} spacing={2}>
          <Button onClick={handleClose} sx={classes.cancelBtn}>
            <Box sx={classes.cancelBtnBox}>
              <Typography className="btnText">{t("cancel")}</Typography>
            </Box>
          </Button>
          <Button onClick={handleLogout} sx={classes.logoutBtn}>
            <Typography className="btnText">{t("logout")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };

  const getLogoutDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={openDialog}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  const getAppHeader = () => {
    return (
      <>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            height: "120px",
            backgroundColor: "#070530",
            boxShadow: "0px 0px",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              height: "110px",
            },
          }}
        >
          <Toolbar
            sx={{
              "&.MuiToolbar-root": {
                px: 0,
                height: "120px",
                width: "96%",
              },
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {/* <Grid item xl={4} lg={4} md={1} sm={0} xs={0}>
                {doesPageHasComponent(strings.BACK) && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    onClick={() => history.goBack()}
                  >
                    {isMobile ? (
                      <img
                        src={backArrow}
                        height={"15px"}
                        alt="Module not found..."
                      />
                    ) : (
                      <img
                        src={backArrow}
                        height={"20px"}
                        alt="Module not found..."
                      />
                    )}

                    <Typography pl={2} sx={classes.backText}>
                      Back
                    </Typography>
                  </Box>
                )}
              </Grid> */}
              <Grid
                item
                xl={4}
                lg={4}
                md={5}
                sm={6}
                xs={6}
                // sx={{ ...centerItemFlex }}
              >
                {getAstrologyLogo()}
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={7}
                sm={6}
                xs={6}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                {!isTablet && getHeaderContent()}
                {isTablet && (
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MenuIcon sx={{ pt: 2, color: "#FFFFFF" }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Divider sx={classes.dividerStyle} />
          </Box>
          {getMenu()}
          {getLogoutDialog()}
          <NotificationDrawer
            open={openNotification}
            setOpen={setOpenNotification}
            drawerWidth="600px"
          />
          {fullHoraDetails && (
            <HoraDrawer
              fullHora={fullHoraDetails}
              open={openHora}
              setOpen={setOpenHora}
              date={date}
              setDate={setDate}
              isLoading={isLoading}
              drawerWidth="550px"
            />
          )}
          {fullChoghadiyaDetails && (
            <ChoghadiyaDrawer
              fullChoghadiya={fullChoghadiyaDetails}
              open={openChoghadiya}
              setOpen={setOpenChoghadiya}
              date={date}
              setDate={setDate}
              isLoading={isLoading}
              drawerWidth="550px"
            />
          )}
        </AppBar>
      </>
    );
  };

  return getAppHeader();
};

export default AppHeader;
