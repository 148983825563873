import urls from "../../global/constants/UrlConstants";
import {
  getCallParams,
  getFileCallParams,
  makeCall,
} from "../../utils/service";

export const getProfessionCategories = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.professionCategories, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getProfessionAttribute = async (category: string) => {
  try {
    const callParams = await getCallParams("POST", { category });
    const response = await makeCall(urls.professionAttribute, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getProfessionAnalysisData = async (data: any) => {
  try {
    const callParams = await getCallParams("POST", data);
    const response = await makeCall(
      urls.professionPredicationChart,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const professionPrediction = async (profile: any) => {
  const body = {
    first_person: profile,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(urls.professionPrediction, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const uploadFile = async (formData: any) => {
  try {
    const callParams = await getFileCallParams(formData);
    const response = await makeCall(urls.uploadFile, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
