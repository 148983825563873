import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { centerItemFlex } from "../../utils/styles";

interface CustomProps {
  percentages: { [key: string]: string };
  needColor?: boolean;
}

const ProfessionGraph: React.FC<CustomProps> = (props: CustomProps) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(() =>
    Object.keys(props.percentages).map((key) => ({
      profession: key,
      percentage: (+props.percentages[key] * 100).toFixed(2).toString(),
    }))
  );

  const chartRef = useRef<am4charts.XYChart3D | null>(null);

  const FIXED_COLORS = ["#FFA62F", "#97BE5A", "#03A9F4", "#A997DF", "#028391"];

  useEffect(() => {
    setChartData(
      Object.keys(props.percentages).map((key) => ({
        profession: key,
        percentage: (+props.percentages[key] * 100).toFixed(2).toString(),
      }))
    );
  }, [props.percentages]);

  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_kelly);

    const chart = am4core.create("chartdiv", am4charts.XYChart3D);
    chartRef.current = chart;

    chart.data = chartData;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "profession";
    categoryAxis.title.text = "Professions";
    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.labels.template.fontWeight = "normal";
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.fill = am4core.color("white");
    categoryAxis.title.fill = am4core.color("white");

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Prediction Percentage";
    valueAxis.renderer.labels.template.fontSize = 17;
    valueAxis.renderer.labels.template.fontWeight = "normal";
    valueAxis.renderer.labels.template.fill = am4core.color("white");
    valueAxis.title.fill = am4core.color("white");

    const series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "percentage";
    series.dataFields.categoryX = "profession";
    series.name = "Percentage";
    series.tooltipText = "{name}: [bold]{valueY}%[/]";
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("black");
      series.tooltip.label.fill = am4core.color("white");
    }

    series.columns.template.adapter.add("fill", (fill: any, target: any) => {
      const index = target.dataItem?.index;
      return props.needColor && index !== undefined
        ? am4core.color(FIXED_COLORS[index % FIXED_COLORS.length])
        : am4core.color("#a5a5a5");
    });

    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{valueY}%";
    valueLabel.label.dy = -25;
    valueLabel.label.dx = 15;
    valueLabel.label.fontSize = 17;
    valueLabel.label.fontWeight = "normal";
    valueLabel.label.fill = am4core.color("white");

    chart.cursor = new am4charts.XYCursor();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [props.needColor, chartData]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = chartData;
    }
  }, [chartData]);

  return (
    <Box
      textAlign="center"
      width="auto"
      sx={{
        ...centerItemFlex,
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
        p: 5,
      }}
    >
      <div id="chartdiv" style={{ width: "100%", minHeight: "100vh" }}></div>
    </Box>
  );
};

export default ProfessionGraph;
