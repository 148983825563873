import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { useEffect, useRef } from "react";
import { capitalizeWords } from "../../helpers/methods";

am4core.useTheme(am4themes_animated);

interface CustomProps {
  chartData: any;
  formFields: any;
}

const ProfessionCharts = (props: CustomProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<any>(null);
  const chartTypeRef = useRef<string | null>(null);

  useEffect(() => {
    initializeChart();
  }, [props.chartData]);

  const addWatermark = (chart: am4core.Container) => {
    const watermark = chart.createChild(am4core.Label);
    watermark.text = "Akari Aryaca Software";
    watermark.fontSize = 30;
    watermark.fill = am4core.color("grey");
    watermark.isMeasured = false;
    watermark.horizontalCenter = "right";
    watermark.verticalCenter = "top";
    watermark.x = chart.pixelWidth - 20;
    watermark.y = -5;
  };

  const addTitle = (chart: any) => {
    const title = chart.titles.create();
    title.text = capitalizeWords(props.formFields.category.value);
    title.fontSize = 22;
    title.fill = am4core.color("#FFFFFF");
    title.textAlign = "middle";
  };

  const addFooter = (chart: am4core.Container) => {
    const footer = chart.createChild(am4core.Label);
    footer.text = capitalizeWords(props.formFields.attributeName.value);
    footer.fontSize = 22;
    footer.fill = am4core.color("#FFFFFF");
    footer.align = "center";
    footer.valign = "bottom";
  };

  const getFontSizeBasedOnDataLength = (dataLength: number) => {
    switch (true) {
      case dataLength <= 9:
        return 21;
      case dataLength <= 12:
        return 19;
      case dataLength <= 18:
        return 17;
      case dataLength <= 23:
        return 15;
      case dataLength <= 27:
        return 13;
      default:
        return 12;
    }
  };

  const getRandomColor = () => {
    let color;
    do {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const brightness = r * 0.299 + g * 0.587 + b * 0.114;
      color = `#${((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)}`;
      if (brightness > 128) {
        break;
      }
    } while (true);

    return color;
  };

  const downloadChart = () => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.exporting.export("png");
    } else {
      console.error("Chart instance not found");
    }
  };

  const getPieChart = (chartContainer: any) => {
    const pieChart = am4core.create(chartContainer, am4charts.PieChart);
    pieChart.data = Object.keys(props.chartData.values).map((key) => ({
      category: key.replace(/\[/g, "[[").replace(/\]/g, "]]"),
      value: props.chartData.values[key].Count,
      percentage: props.chartData.values[key].Percentage,
    }));

    pieChart.innerRadius = am4core.percent(40);

    const legend = (pieChart.legend = new am4charts.Legend());
    legend.position = "right";
    legend.valueLabels.template.text =
      props.formFields.percentage.value === "0" ? "{percentage}" : "{value}";
    legend.labels.template.fill = am4core.color("#FFFFFF");
    legend.valueLabels.template.fill = am4core.color("#FFFFFF");

    if (pieChart.tooltip) {
      pieChart.tooltip.label.fill = am4core.color("#FFFFFF");
      pieChart.tooltip.background.fill = am4core.color("#000000");
      pieChart.tooltip.tooltipText =
        props.formFields.percentage.value === "0"
          ? "{category}: {percentage}%"
          : "{category}: {value}";
    }

    const pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value =
      props.formFields.percentage.value === "0" ? "percentage" : "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.labels.template.text =
      props.formFields.percentage.value === "0"
        ? "{category}: {percentage}%"
        : "{category}: {value}";
    pieSeries.labels.template.fill = am4core.color("#FFFFFF");
    pieSeries.ticks.template.stroke = am4core.color("#FFFFFF");
    pieSeries.ticks.template.strokeOpacity = 0.8;

    pieSeries.slices.template.adapter.add("fill", () => {
      return am4core.color(getRandomColor());
    });

    pieChart.exporting.backgroundColor = am4core.color("#000000");
    addWatermark(pieChart);
    addTitle(pieChart);
    addFooter(pieChart);
    chartInstanceRef.current = pieChart;
    chartTypeRef.current = "Pie";

    return () => {
      pieChart.dispose();
    };
  };

  const getBarChart = (chartContainer: any) => {
    const barChart = am4core.create(chartContainer, am4charts.XYChart3D);

    barChart.data = Object.keys(props.chartData.values)
      .map((key) => ({
        category: key.replace(/\[/g, "[[").replace(/\]/g, "]]"),
        value: props.chartData.values[key].Count,
        percentage: props.chartData.values[key].Percentage,
      }))
      .sort((a, b) => {
        const getNumericPrefix = (str: string) =>
          parseInt(str.split(".")[0], 10);
        return getNumericPrefix(a.category) - getNumericPrefix(b.category);
      });

    const categoryAxis = barChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 18;
    categoryAxis.renderer.labels.template.rotation = 305;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    categoryAxis.renderer.labels.template.fontSize =
      getFontSizeBasedOnDataLength(barChart.data.length);
    categoryAxis.renderer.labels.template.text = "{category}";
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 300;
    categoryAxis.renderer.labels.template.truncate = false;
    categoryAxis.tooltipText = "{category}";

    const valueAxis = barChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text =
      props.formFields.percentage.value === "0"
        ? "Percentage"
        : "No of Occurrences";
    valueAxis.title.fontSize = getFontSizeBasedOnDataLength(
      barChart.data.length
    );
    valueAxis.title.fill = am4core.color("white");
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    valueAxis.renderer.labels.template.fontSize = getFontSizeBasedOnDataLength(
      barChart.data.length
    );

    const series = barChart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY =
      props.formFields.percentage.value === "0" ? "percentage" : "value";
    series.dataFields.categoryX = "category";
    series.name =
      props.formFields.percentage.value === "0" ? "Percentage" : "Count";

    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text =
      props.formFields.percentage.value === "0" ? "{valueY}%" : "{valueY}";
    valueLabel.label.dy = 10;
    valueLabel.label.dx = 0;
    valueLabel.label.fontSize = getFontSizeBasedOnDataLength(
      barChart.data.length
    );
    valueLabel.label.fontWeight = "normal";
    valueLabel.label.fill = am4core.color("black");

    series.columns.template.adapter.add("fill", () => {
      return am4core.color(getRandomColor());
    });

    series.columns.template.tooltipText =
      props.formFields.percentage.value === "0"
        ? "{categoryX}: {valueY}%"
        : "{categoryX}: {valueY}";

    barChart.exporting.backgroundColor = am4core.color("#000000");
    addWatermark(barChart);
    addTitle(barChart);
    addFooter(barChart);
    chartInstanceRef.current = barChart;
    chartTypeRef.current = "Bar";

    return () => {
      barChart.dispose();
    };
  };

  const initializeChart = () => {
    const chartContainer = chartContainerRef.current;
    if (!chartContainer) {
      console.error("Chart container is not available.");
      return;
    }

    if (props.chartData.graphType === "Pie chart") {
      getPieChart(chartContainer);
    } else if (props.chartData.graphType === "Bar chart") {
      getBarChart(chartContainer);
    }
  };

  const getProfessionCharts = () => {
    if (
      props.chartData &&
      typeof props.chartData === "object" &&
      Object.keys(props.chartData).length > 0
    ) {
      return (
        <>
          <IconButton
            onClick={downloadChart}
            color="primary"
            aria-label="Download chart"
          >
            <DownloadIcon />
            Download
          </IconButton>
          <div
            ref={chartContainerRef}
            style={{ width: "100%", height: "100vh", marginTop: "30px" }}
          />
        </>
      );
    } else return null;
  };

  return getProfessionCharts();
};

export default ProfessionCharts;
