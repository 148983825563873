import {
  Box,
  Container,
  Grid,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import backArrow from "../../assets/icons/backArrow.svg";
import { CustomButton } from "../../global/components";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import strings from "../../global/constants/StringConstants";
import { handleErrorMessage } from "../../helpers/methods";
import history from "../../utils/history";
import { theme } from "../../utils/styles";
import Profession from "./Profession";
import professionAnalysisStyle from "./ProfessionAnalysis.styles";
import {
  getProfessionAnalysisData,
  getProfessionAttribute,
  getProfessionCategories,
} from "./ProfessionAnalysisService";
import ProfessionCharts from "./ProfessionCharts";
import UploadCSVDialog from "./UploadDialog";

const ProfessionAnalysis = () => {
  const classes = professionAnalysisStyle;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const categoryMenu = [
    { value: -1, content: `${t("categoryPlace")}`, disabled: true },
  ];

  const attributeMenu = [
    { value: -1, content: `${t("attributePlace")}`, disabled: true },
  ];
  const initialFormData = {
    category: {
      value: "-1",
      error: "",
    },
    attributeName: {
      value: "-1",
      error: "",
    },
    attributeIndexNumber: {
      value: "",
      error: "",
    },
    graphType: {
      value: "Bar chart",
      error: "",
    },
    percentage: {
      value: "0",
      error: "",
    },
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [attributes, setAttributes] = useState<any>(attributeMenu);
  const [attributesIndex, setAttributesIndex] = useState<any>();
  const [chartData, setChartData] = useState<any>();
  const [option, setOption] = useState<string>("chart");
  const [formFields, setFormFields] = useState<any>(initialFormData);

  const optionMenu = [
    { value: "chart", content: "Chart" },
    { value: "prediction", content: "Prediction" },
  ];

  const graphMenu = [
    { value: "Pie chart", content: "Pie Chart" },
    { value: "Bar chart", content: "Bar Chart" },
  ];

  const percentageMenu = [
    { value: "0", content: "Percentage" },
    { value: "1", content: "Count" },
  ];

  useEffect(() => {
    getProfessionCategory();
  }, []);

  useEffect(() => {
    if (formFields.category.value !== "-1") {
      getProfessionAttributes();
    }
  }, [formFields.category.value]);

  const formValidation = (formData: any) => {
    let errors = formData;
    let isValid = true;
    const category = formData.category.value;
    const attributeName = formData.attributeName.value;
    const graphType = formData.graphType.value;
    if (category === "-1") {
      errors.category.error = t("categoryErrorMessage");
      isValid = false;
    }
    if (attributeName === "-1") {
      errors.attributeName.error = t("attributeErrorMessage");
      isValid = false;
    }
    if (graphType === "-1") {
      errors.graphType.error = t("chartErrorMessage");
      isValid = false;
    }
    return { isValid, errors };
  };

  const handleValidation = () => {
    const { isValid, errors } = formValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getProfessionCategory = async () => {
    try {
      setIsLoading(true);
      const response = await getProfessionCategories();
      const responseCategories = response.map((item: string) => ({
        value: item,
        content: item,
      }));
      setCategories([...categoryMenu, ...responseCategories]);
    } catch (error: any) {
      handleErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setOption(event.target.value);
    setChartData({});
    setAttributes(attributeMenu);
    setFormFields(initialFormData);
  };

  const getProfessionAttributes = async () => {
    try {
      setIsLoading(true);
      const response = await getProfessionAttribute(formFields.category.value);
      const responseAttributes = response.map((item: string) => ({
        value: item,
        content: item,
      }));
      setAttributes([...attributes, ...responseAttributes]);
      setAttributesIndex(
        response.reduce((acc: any, key: any, index: number) => {
          acc[key] = index;
          return acc;
        }, {})
      );
    } catch (error: any) {
      handleErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveProfile = async () => {
    try {
      if (handleValidation()) {
        const obj = {
          category: formFields.category.value,
          attributeName: formFields.attributeName.value,
          attributesIndex: attributesIndex[formFields.attributeName.value],
          graphType: formFields.graphType.value,
        };
        setIsLoading(true);
        const response = await getProfessionAnalysisData(obj);
        setChartData(response);
        setIsLoading(false);
      }
    } catch (error: any) {
      handleErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = (event: any) => {
    if (event.target.name === "category") {
      setAttributes(attributeMenu);
    }
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
  };

  const getHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { sm: "center", xs: "baseline" },
          width: "100%",
        }}
        pb={3}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => history.goBack()}
        >
          {isMobile ? (
            <img src={backArrow} height={"20px"} alt="Module not found..." />
          ) : (
            <img src={backArrow} height={"25px"} alt="Module not found..." />
          )}
        </Box>
        <Typography sx={classes.italicTypo}>{t("professionHeader")}</Typography>
        <Box sx={{ display: "flex", gap: "20px" }}>
          {option === "chart" && (
            <CustomButton
              label={t("↓↑")}
              type={strings.SECONDARY}
              onClick={() => setOpen(true)}
              customClasses={{
                minWidth: "50px",
                width: "50px",
                borderRadius: "50%",
              }}
            />
          )}
          <CustomSelect
            menuItems={optionMenu}
            onChange={handleChange}
            value={option}
            name={"house"}
            id={"house"}
            customClasses={classes.darkTextField}
          />
        </Box>
      </Box>
    );
  };

  const getForm = () => {
    return (
      <Grid container spacing={1} justifyContent={"center"}>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("category")}
            menuItems={categories}
            onChange={handleFieldChange}
            id={"category"}
            name={"category"}
            value={formFields.category.value}
            error={formFields.category.error}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("attribute")}
            menuItems={attributes}
            onChange={handleFieldChange}
            id={"attributeName"}
            name={"attributeName"}
            value={formFields.attributeName.value}
            error={formFields.attributeName.error}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("chart")}
            menuItems={graphMenu}
            onChange={handleFieldChange}
            id={"graphType"}
            name={"graphType"}
            value={formFields.graphType.value}
            error={formFields.graphType.error}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("dataView")}
            menuItems={percentageMenu}
            onChange={handleFieldChange}
            id={"percentage"}
            name={"percentage"}
            value={formFields.percentage.value}
            error={formFields.percentage.error}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} mt={3}>
          <CustomButton
            label={t("generate")}
            type={strings.PRIMARY}
            onClick={handleSaveProfile}
          />
        </Grid>
      </Grid>
    );
  };

  const getProfessionAnalysis = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="xl" sx={{ px: 1 }}>
                {getHeader()}
                {option === "chart" && (
                  <>
                    {getForm()}
                    {chartData && (
                      <ProfessionCharts
                        chartData={chartData}
                        formFields={formFields}
                      />
                    )}
                  </>
                )}
                {option === "prediction" && (
                  <Profession setIsLoading={setIsLoading} />
                )}
              </Container>
            </Box>
          </Box>
        </Box>
        <UploadCSVDialog
          open={open}
          handleClose={() => setOpen(false)}
          setIsLoading={setIsLoading}
          categoryMenu={categories}
        />
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getProfessionAnalysis();
};

export default ProfessionAnalysis;
