import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "react-mui-dropzone";
import { CustomDialog } from "../../global/components";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import {
  handleErrorMessage,
  openSuccessNotification,
} from "../../helpers/methods";
import subscriptionStyles from "../Subscriptions/Subscriptions.styles";
import { uploadFile } from "./ProfessionAnalysisService";

interface CustomProps {
  setIsLoading: Function;
  handleClose: Function;
  open: boolean;
  categoryMenu: any;
}

const useStyles = makeStyles({
  dropzone: {
    border: "2px dashed #9053F6",
    borderRadius: "8px",
    color: "#9053F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    backgroundColor: "#F8F8FF",
  },
  icon: {
    color: "#9053F6",
    fontSize: "48px",
  },
});

const UploadCSVDialog = (props: CustomProps) => {
  const classes = subscriptionStyles;
  const dropzoneClasses = useStyles();
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [category, setCategory] = useState<any>({
    value: "-1",
    error: "",
  });

  const handleChange = async (files: File[]) => {
    setFile(files[0]);
  };

  const handleUploadFile = async () => {
    try {
      if (file) {
        props.setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const response = await uploadFile(formData);
        openSuccessNotification(response.message);
        props.handleClose();
      } else {
        throw {
          errorMessage: "Please select a file to upload",
          errorCode: 500,
        };
      }
    } catch (error: any) {
      handleErrorMessage(error);
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (category.value === "-1") {
      setCategory({
        value: category.value,
        error: "Please select category",
      });
      isValid = false;
    }
    return isValid;
  };

  const handleDownload = () => {
    if (handleValidation()) {
      const filePath = "/sample_file.csv";
      const link = document.createElement("a");
      link.href = filePath;
      link.download = `${category.value}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFieldChange = (event: any) => {
    setCategory({
      value: event.target.value,
      error: "",
    });
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {t("uploadCSVHeader")}
        </Typography>

        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} pb={5} spacing={2} sx={classes.dialogContent}>
        <Grid container>
          <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
            <CustomSelect
              menuItems={props.categoryMenu}
              onChange={handleFieldChange}
              id={"category"}
              name={"category"}
              customClasses={classes.gradientTextField}
              value={category.value}
              error={category.error}
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            alignContent={"center"}
            textAlign={"end"}
          >
            <IconButton
              onClick={handleDownload}
              color="primary"
              aria-label="Download chart"
              sx={classes.uploadCSVButton}
            >
              {t("uploadCSVContent")}
            </IconButton>
          </Grid>
        </Grid>

        <DropzoneArea
          acceptedFiles={[
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          dropzoneText={t("dropzoneText")}
          onChange={handleChange}
          filesLimit={1}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewText=""
          classes={{
            root: dropzoneClasses.dropzone,
            icon: dropzoneClasses.icon,
          }}
        />
        <Stack
          direction={{ sm: "row", xs: "column" }}
          spacing={2}
          alignSelf={"center"}
        >
          <Button onClick={handleUploadFile} sx={classes.logoutBtn}>
            <Typography className="btnText">{t("upload")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };
  const getUploadCSVDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={props.open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={() => props.handleClose()}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };
  return getUploadCSVDialog();
};

export default UploadCSVDialog;
