import baseUrl from "./BaseURL";

export class StringConstants {
  PROD = false;

  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };
  apiKey = "AIzaSyCTkF9hPY1CXSk7voqCdHZp7ci-4PG6tHU";
  STRIPE_PUBLIC_KEY = baseUrl.STRIPE_PUBLIC_KEY;
  VAPID_KEY =
    "BPyj7elQ5h70iznlSRjdfEsqV7kS_ry4eg9rAmf7uWI7uv6neaEjpjm94GF14nLkbkaEFXGVl0UWYiohtZzFULw";
  FOOTBALL_ACCESS_TOKEN =
    "gAAAAABmkShWlokJbS9hei4MZvGb2wgZpwpQAHgzCu1KIrbtAP_va39HWQVM_mPlgQvbWV_rwAgK_INWrSWypJ-IIr6e0BAlpfJn2yrJY_J9YE7ZTWzyNUEse2oTBgoDiB3m05XPc349t7z35o_a0JwigrYyOfc3gg==";

  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  SEARCH_TIME_OUT = 350;

  //Landing Page
  HOME = "";
  LOGIN = "login";
  REGISTER = "register";
  SET_PASSWORD = "set-password";
  OTP = "otp";
  RESET_PASSWORD = "reset-password";
  DELETE_ACCOUNT = "delete-account";

  //pages
  PRIVACY_POLICY = "privacy-policy";
  DASHBOARD = "dashboard";
  PROFILE = "profile";
  CALCULATOR = "calc";
  PROFESSION_ANALYSIS = "profession-analysis";
  ANALYSIS = "analysis";
  PRICING = "pricing";
  PAYMENT = "payment";
  SUCCESS = "success";
  FAIL = "fail";
  USER_PROFILE = "my-profile";
  SUBSCRIPTION = "subscription";
  UPDATE_PASSWORD = "update-password";

  //components
  BACK = "back";
  MOON = "moon";
  NOTIFICATION = "notification";

  //Email validation Regex
  regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  //Phone Number Regex
  phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // tab value
  ANALYSIS_TAB = "analysis";
  MINERALS_TAB = "minerals";
  MAHADASHA_TAB = "mahadasha";
  PLANET_TAB = "planet-details";
  FOOTBALL_TAB = "football-position-prediction";
  NAKSHATRA_TAB = "nakshatra-analysis";
  CIRCULAR_CHARTS_TAB = "circular-chart";
  DATA = "Data";
  LEGENDS = "Legends";
  PLANET = "Planet";
  RAYS = "Soul Rays";
  GOOD_TIME = "Good Time";
  BAD_TIME = "Bad Time";

  //Button
  PRIMARY = "primary";
  SECONDARY = "secondary";

  // role

  SUPER_ADMIN = "Super Admin";
  ADMIN = "ADMIN";
  USER = "USER";

  // authManager
  // HOME = "Home";
  PAGENOTFOUND = "Page Not Found";

  jpeg = ".jpeg";
  JPEG = ".JPEG";
  JPG = "JPG";
  jpg = ".jpg";
  png = ".png";
  PNG = ".PNG";
  gif = ".gif";
  bmp = ".bmp";
  tiff = ".tiff";

  view = "View";
  download = "Download";
}

let strings = new StringConstants();
export default strings;
