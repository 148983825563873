import baseUrl from "./BaseURL";
import { StringConstants } from "./StringConstants";

class UrlConstants extends StringConstants {
  url = baseUrl.url;

  timeout = "timeout";
  public = "public";
  auth = "auth";
  user = "user";
  profile = "profile";
  subscription = "subscription";
  invoice = "invoice";
  payment = "payment";
  dasha = "dasha";
  person = "person";
  astrology = "astro";
  notification = "notification";
  analysis = "analysis";

  PUBLIC_URL = `${this.url}/${this.public}`;
  AUTH_URL = `${this.url}/${this.auth}`;
  USER_URL = `${this.url}/${this.user}`;
  SUBSCRIPTION_URL = `${this.url}/${this.subscription}`;
  INVOICE_URL = `${this.url}/${this.invoice}`;
  PAYMENT_URL = `${this.url}/${this.payment}`;
  DASHA_URL = `${this.url}/${this.dasha}`;
  PERSON_URL = `${this.url}/${this.person}`;
  ASTROLOGY_URL = `${this.url}/${this.astrology}`;
  NOTIFICATION_URL = `${this.url}/${this.notification}`;
  ANALYSIS_URL = `${this.url}/${this.analysis}`;

  // view-path
  homeViewPath = "/";
  loginViewPath = "/login";
  registerViewPath = "/register";
  resetPasswordViewPath = "/reset-password";
  setPasswordViewPath = "/set-password";
  dashboardViewPath = "/dashboard";
  professionAnalysisViewPath = "/profession-analysis";
  profileViewPath = "/profile";
  calculateViewPath = "/calc";
  analysisViewPath = "/analysis";
  missingMineralsViewPath = "/minerals";
  mahadashaViewPath = "/mahadasha";
  planetDetailsViewPath = "/planet-details";
  footballPredictionViewPath = "/football-position-prediction";
  nakshatraAnalysisViewPath = "/nakshatra-analysis";
  circularChartViewPath = "/circular-chart";
  pricingViewPath = "/pricing";
  paymentViewPath = "/payment";
  userProfileViewPath = "/my-profile";
  updatePasswordViewPath = "/update-password";
  subscriptionViewPath = "/subscription";
  successViewPath = "/success";
  failViewPath = "/fail";
  deleteAccountViewPath = "/delete-account";
  privacyPolicyViewPath = "/privacy-policy";

  // apis

  //public
  loginUser = `${this.PUBLIC_URL}/login`;
  registerUser = `${this.PUBLIC_URL}/sign-up`;
  resetPassword = `${this.PUBLIC_URL}/reset-password`;

  //auth
  verifyOtp = `${this.AUTH_URL}/verify-otp`;
  resendOtp = `${this.AUTH_URL}/resend-otp`;
  setPassword = `${this.AUTH_URL}/set-password`;
  logout = `${this.AUTH_URL}/logout`;

  //user
  getUserProfile = `${this.USER_URL}/`;
  updateUserProfile = `${this.USER_URL}/`;
  updatePassword = `${this.USER_URL}/update-password`;
  getProfile = `${this.USER_URL}/${this.profile}/`;
  getProfileById = `${this.USER_URL}/${this.profile}`;
  addProfile = `${this.USER_URL}/${this.profile}/`;
  deleteProfile = `${this.USER_URL}/${this.profile}/`;
  makeDefault = `${this.USER_URL}/${this.profile}`;
  updateProfile = `${this.USER_URL}/${this.profile}`;
  deleteAccount = `${this.USER_URL}/`;
  setLanguagePreference = `${this.USER_URL}/set-preference`;

  //subscription
  getPlans = `${this.SUBSCRIPTION_URL}/plan`;
  getSubscription = `${this.SUBSCRIPTION_URL}`;
  createSubscription = `${this.SUBSCRIPTION_URL}/`;
  updatePlan = `${this.SUBSCRIPTION_URL}/`;
  setPromoCode = `${this.SUBSCRIPTION_URL}/promocode`;
  cancelSubscription = `${this.SUBSCRIPTION_URL}/`;
  getPayPalUrl = `${this.SUBSCRIPTION_URL}/paypal-checkout`;
  getSuccessDetails = `${this.SUBSCRIPTION_URL}/success-details`;

  //invoice
  getInvoices = `${this.INVOICE_URL}`;

  //payment
  getPaymentMethods = `${this.PAYMENT_URL}/`;
  addPaymentMethods = `${this.PAYMENT_URL}/`;
  makeDefaultPaymentMethod = `${this.PAYMENT_URL}`;
  deletePaymentMethod = `${this.PAYMENT_URL}`;

  //person
  analysisData = `${this.PERSON_URL}/analysis`;
  missingMinerals = `${this.PERSON_URL}/missing-minerals`;
  getMoonPhases = `${this.PERSON_URL}/moon-phase`;
  planetData = `${this.PERSON_URL}/details/planet`;
  footballPrediction = `${this.PERSON_URL}/football-prediction`;
  circularChart = `${this.PERSON_URL}/circular-chart-xiega`;
  choghadiya = `${this.PERSON_URL}/choghadiya`;
  fullChoghadiya = `${this.PERSON_URL}/full_choghadiya`;
  hora = `${this.PERSON_URL}/hora`;
  fullHora = `${this.PERSON_URL}/full_hora`;
  professionPrediction = `${this.PERSON_URL}/profession-prediction`;
  uploadFile = `${this.PERSON_URL}/upload_profession_csv`;

  //dasha
  mahadashaData = `${this.DASHA_URL}/mahadasha`;
  maleficDashaData = `${this.DASHA_URL}/malefic-occurrence-pattern`;
  beneficDashaData = `${this.DASHA_URL}/benefic-occurrence-pattern`;
  innerDashaCosmicData = `${this.DASHA_URL}/innerdasha-cosmic`;

  //notification
  notificationList = `${this.NOTIFICATION_URL}/`;
  notificationCount = `${this.NOTIFICATION_URL}/count`;

  //general
  buyNowUrl = `${this.ASTROLOGY_URL}/web-url`;
  contactUsApi = `${this.ASTROLOGY_URL}/contact-us`;

  //analysis
  professionCategories = `${this.ANALYSIS_URL}/analyse-profession-keys`;
  professionAttribute = `${this.ANALYSIS_URL}/analyse-profession-attribute-keys`;
  professionPredicationChart = `${this.ANALYSIS_URL}/analyse-profession`;
}

let urls = new UrlConstants();
export default urls;
