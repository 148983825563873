import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";

export interface AuthState {
  authenticated: boolean;
  accessToken: string;
  loading: boolean;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  contact: string;
  tempToken: string;
  isSubscribed: boolean;
  profileCount: number;
  profileId: string;
  language: string;
  firebaseToken: string | any;
}

const initialState: AuthState = {
  authenticated: false,
  accessToken: "",
  loading: false,
  email: "",
  firstName: "",
  lastName: "",
  role: "",
  contact: "",
  tempToken: "",
  isSubscribed: false,
  profileCount: 0,
  profileId: "",
  language: "",
  firebaseToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        accessToken: string;
        email: string;
        firstName: string;
        lastName: string;
        role: string;
        isSubscribed: boolean;
        profileCount: number;
        profileId: string;
      }>
    ) => {
      state.authenticated = action.payload.authenticated;
      state.accessToken = action.payload.accessToken;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role;
      state.isSubscribed = action.payload.isSubscribed;
      state.profileCount = action.payload.profileCount;
      state.profileId = action.payload.profileId;
    },
    addLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logOutAction: (state, action: {}) => {
      state.authenticated = false;
      state.loading = false;
      state.accessToken = "";
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.role = "";
      state.profileId = "";
      state.profileCount = 0;
      state.isSubscribed = false;
      state.firebaseToken = "";
    },
    tempTokenAction: (
      state,
      action: PayloadAction<{
        tempToken: string;
      }>
    ) => {
      state.tempToken = action.payload.tempToken;
    },
    tempTokenRemoveAction: (state, action: {}) => {
      state.tempToken = "";
    },
    subscriptionAction: (
      state,
      action: PayloadAction<{ isSubscribed: boolean }>
    ) => {
      state.isSubscribed = action.payload.isSubscribed;
    },
    cancelSubscriptionAction: (state, action: {}) => {
      state.isSubscribed = false;
    },
    profileUpdateAction: (
      state,
      action: PayloadAction<{ firstName: string; lastName: string }>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setLanguageAction: (state, action: PayloadAction<{ language: string }>) => {
      state.language = action.payload.language;
    },
    setProfileIdAction: (
      state,
      action: PayloadAction<{ profileId: string }>
    ) => {
      state.profileId = action.payload.profileId;
    },
    firebaseTokenAction: (
      state,
      action: PayloadAction<{
        firebaseToken: string | any;
      }>
    ) => {
      state.firebaseToken = action.payload.firebaseToken;
    },
    setProfileCountAction: (
      state,
      action: PayloadAction<{ profileCount: number }>
    ) => {
      state.profileCount = action.payload.profileCount;
    },
  },
});

export const {
  loginAction,
  logOutAction,
  addLoading,
  tempTokenAction,
  tempTokenRemoveAction,
  subscriptionAction,
  cancelSubscriptionAction,
  profileUpdateAction,
  setLanguageAction,
  firebaseTokenAction,
  setProfileIdAction,
  setProfileCountAction,
} = authSlice.actions;

export const selectAuthenticated = (state: RootState) =>
  state.auth.authenticated;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectUserEmail = (state: RootState) => state.auth.email;
export const selectFirstName = (state: RootState) => state.auth.firstName;
export const selectRole = (state: RootState) => state.auth.role;
export const selectIsSubscribed = (state: RootState) => state.auth.isSubscribed;
export const selectProfileCount = (state: RootState) => state.auth.profileCount;
export const selectTempToken = (state: RootState) => state.auth.tempToken;
export const selectProfileId = (state: RootState) => state.auth.profileId;
export const selectLanguage = (state: RootState) => state.auth.language;
export const selectFirebaseToken = (state: RootState) =>
  state.auth.firebaseToken;

export default authSlice.reducer;
