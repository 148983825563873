import CloseIcon from "@mui/icons-material/Close";
import NightsStayRoundedIcon from "@mui/icons-material/NightsStayRounded";
import WbTwilightRoundedIcon from "@mui/icons-material/WbTwilightRounded";
import {
  Box,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { pureWhiteColor, theme } from "../../../utils/styles";
import appHeaderStyles from "./AppHeader.styles";
import HoraLegends from "./HoraLegends";

interface CustomProps {
  fullChoghadiya: any;
  open: boolean;
  setOpen: Function;
  date: string;
  setDate: Function;
  isLoading: boolean;
  drawerWidth?: any;
}

const ChoghadiyaDrawer = (props: CustomProps) => {
  const classes = appHeaderStyles;
  const drawerWidth = props.drawerWidth ? props.drawerWidth : 300;
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(`${t("nakshatra")}`);

  useEffect(() => {
    setTabValue(`${t("nakshatra")}`);
  }, [i18n.language]);

  const tabConfig = [
    {
      label: `${t("nakshatra")}`,
    },
    {
      label: `${t("legendsHeader")}`,
    },
  ];

  const commonStyles = {
    grey: {
      background: "#505050",
      color: "#e9d8b2",
    },
    red: {
      background: "#a21010",
      color: "#e9d8b2",
    },
    lightGreen: {
      background: "#839626",
      color: "#e9d8b2",
    },
    darkGreen: {
      background: "#557a10",
      color: "#e9d8b2",
    },
  };

  const horaBackgroundColorMapping: any = {
    Neutral: commonStyles.grey,
    Loss: commonStyles.red,
    Evil: commonStyles.red,
    Bad: commonStyles.red,
    Good: commonStyles.lightGreen,
    Gain: commonStyles.lightGreen,
    Best: commonStyles.darkGreen,
    Neutralny: commonStyles.grey,
    Strata: commonStyles.red,
    Zło: commonStyles.red,
    Zły: commonStyles.red,
    Dobry: commonStyles.lightGreen,
    Zysk: commonStyles.lightGreen,
    Najlepszy: commonStyles.darkGreen,
  };

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  const handleChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const getChoghadiyaDrawer = () => {
    return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
          "& .MuiDrawer-paper": {
            background: "#05022A",
            width: drawerWidth,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              border: "1px solid #FFFFFF",
              background: "#282945",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={props.open}
      >
        <Stack
          direction={"row"}
          sx={classes.notificationHeaderBox}
          pt={6}
          px={{ sm: 5, xs: 2.5 }}
        >
          <Stack direction={"row"} spacing={2}>
            <Typography sx={classes.notificationHeader}>Choghadiya</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                border: "1px solid white",
                borderRadius: "50px",
                padding: "2px",
              }}
            >
              <CloseIcon fontSize="small" htmlColor={pureWhiteColor} />
            </IconButton>
          </Stack>
        </Stack>
        {/* <Box mt={3}>
          <CustomTabs
            changeValue={handleChange}
            selected={tabValue}
            tabConfig={tabConfig}
            activeTabStyle={{
              backgroundColor: "none !important",
              border: "1px solid rgb(144, 83, 246)",
            }}
            inActiveTabStyle={{
              backgroundColor: "black !important",
            }}
          />
        </Box> */}
        {tabValue === `${t("nakshatra")}` ? (
          <>
            <Box display={"flex"} justifyContent={"center"} mt={3} mb={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(props.date, "DD-MM-YYYY")}
                  onChange={(newValue: any) => {
                    const formattedDate = newValue.format("DD-MM-YYYY");
                    props.setDate(formattedDate);
                  }}
                  sx={classes.datePicker}
                />
              </LocalizationProvider>
            </Box>
            <Stack direction={"column"} my={3} spacing={3}>
              <Box sx={classes.notificationDateBox}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    sx={classes.notificationDateText}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    Day Choghadiya
                  </Typography>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    px={{ sm: 5, xs: 2.5 }}
                    alignItems={"center"}
                  >
                    <WbTwilightRoundedIcon
                      htmlColor="#ffffff"
                      fontSize="medium"
                    />
                    {props.isLoading ? (
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "23px",
                          minWidth: "100px",
                          background: "#ffffff70",
                        }}
                      />
                    ) : (
                      <Typography sx={classes.notificationDateText}>
                        {moment(props.fullChoghadiya.todaySunrise).format(
                          "HH:mm"
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Box>
              {props.isLoading
                ? [...Array(12)].map((_, index) => {
                    return (
                      <Stack
                        direction={"row"}
                        spacing={{ sm: 4, xs: 2 }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        px={{ sm: 5, xs: 2.5 }}
                      >
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "50px",
                            minWidth: "100px",
                            background: "#ffffff70",
                          }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "30px",
                            minWidth: "200px",
                            background: "#ffffff70",
                          }}
                        />
                      </Stack>
                    );
                  })
                : props.fullChoghadiya.dayHora.map((hora: any) => {
                    const inputString = hora.nakshatra;
                    const mood = inputString.split(" - ")[1];
                    const { background, color } = horaBackgroundColorMapping[
                      mood
                    ] || {
                      background: "#fff",
                      color: "#000",
                    };
                    return (
                      <Stack
                        direction={"row"}
                        spacing={{ sm: 4, xs: 2 }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        px={{ sm: 5, xs: 2.5 }}
                      >
                        <Box
                          sx={{ background, borderRadius: "5px" }}
                          p={{ sm: 2, xs: 1 }}
                        >
                          <Typography
                            sx={{ ...classes.notificationDateText, color }}
                          >
                            {inputString}
                          </Typography>
                        </Box>
                        <Typography sx={classes.notificationDateText}>
                          {moment(
                            hora.start_time,
                            "DD-MM-YYYY HH:mm:ss"
                          ).format("HH:mm")}{" "}
                          to{" "}
                          {moment(hora.end_time, "DD-MM-YYYY HH:mm:ss").format(
                            "HH:mm"
                          )}
                        </Typography>
                      </Stack>
                    );
                  })}
              <Box sx={classes.notificationDateBox}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    sx={classes.notificationDateText}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    Night Choghadiya
                  </Typography>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    px={{ sm: 5, xs: 2.5 }}
                    alignItems={"center"}
                  >
                    <NightsStayRoundedIcon
                      htmlColor="#ffffff"
                      fontSize="medium"
                    />
                    {props.isLoading ? (
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "23px",
                          minWidth: "100px",
                          background: "#ffffff70",
                        }}
                      />
                    ) : (
                      <Typography sx={classes.notificationDateText}>
                        {moment(props.fullChoghadiya.todaySunset).format(
                          "HH:mm"
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Box>
              {props.fullChoghadiya.nightHora.map((hora: any) => {
                const inputString = hora.nakshatra;
                const mood = inputString.split(" - ")[1];
                const { background, color } = horaBackgroundColorMapping[
                  mood
                ] || {
                  background: "#fff",
                  color: "#000",
                };
                return (
                  <Stack
                    direction={"row"}
                    spacing={{ sm: 4, xs: 2 }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    <Box
                      sx={{ background, borderRadius: "5px" }}
                      p={{ sm: 2, xs: 1 }}
                    >
                      <Typography
                        sx={{ ...classes.notificationDateText, color }}
                      >
                        {inputString}
                      </Typography>
                    </Box>
                    <Typography sx={classes.notificationDateText}>
                      {moment(hora.start_time, "DD-MM-YYYY HH:mm:ss").format(
                        "HH:mm"
                      )}{" "}
                      to{" "}
                      {moment(hora.end_time, "DD-MM-YYYY HH:mm:ss").format(
                        "HH:mm"
                      )}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </>
        ) : (
          <HoraLegends />
        )}
      </Drawer>
    );
  };
  return getChoghadiyaDrawer();
};

export default ChoghadiyaDrawer;
